<template>
  <div>
    <breadcrumb-nav>
      <template v-slot:firstMenu>客服管理</template>
      <template v-slot:secondMenu>热门咨询</template>
    </breadcrumb-nav>
    <el-card style="margin: 10px 0px">
      <el-alert style="margin-bottom: 10px;" type="warning" title="注意：创建热门咨询前请先选择店铺。" :closable="false" show-icon></el-alert>
      <shop-select @shopChange="shopChange" :system="true"></shop-select>
      <el-button
        :disabled="isBtnDisabled"
        type="primary"
        icon="el-icon-plus"
        style="margin: 10px 0px"
        @click="showDialog"
      >添加
      </el-button>
    </el-card>
    <el-table style="width: 100%" border :data="list">
      <el-table-column type="index" label="序号" width="80px" align="center">
      </el-table-column>
      <el-table-column prop="question" label="问题">
      </el-table-column>
      <el-table-column prop="type" label="类型">
        <template slot-scope="scope">{{ scope.row.type | formatType }}</template>
      </el-table-column>
      <el-table-column prop="answer" label="答案">
        <template slot-scope="scope">
          <span v-if="scope.row.type== 'text'">
            <div>{{scope.row.answer}}</div>
          </span>
          <span v-if="scope.row.type== 'card'">
            <div style="color:#000;margin-bottom: 20px;">{{scope.row.answer.title}}</div>
            <div v-for="(item, index) in scope.row.answer.list" :key="index" class="content">
              {{index + 1}}、{{item}}
            </div>
          </span>
        </template>
      </el-table-column>
      <el-table-column prop="prop" label="操作" width="200px">
        <template slot-scope="{row, $index}">
          <el-row>
            <el-button
              type="warning"
              icon="el-icon-edit"
              size="mini"
              @click="updateKnowledge(row)"
            >修改</el-button
            >
            <el-button
              type="danger"
              icon="el-icon-delete"
              size="mini"
              @click="delKnowledge(row)"
            >删除</el-button
            >
          </el-row>
          <el-row>
            <el-button icon="el-icon-top" style="margin-left: 0;margin-top:10px;" type="success" size="mini" @click="upindex($index,row)">上移</el-button>
            <el-button icon="el-icon-bottom" type="success" size="mini"  @click="downindex($index,row)">下移</el-button>
          </el-row>
        </template>
      </el-table-column>
    </el-table>
    <el-dialog
      :title="form.id ? '修改热门咨询' : '添加热门咨询'"
      :visible.sync="dialogFormVisible"
    >
      <!-- form表单 :model属性，这个属性的作用是,把表单的数据收集到那个对象的身上 ，将来表单验证，也需要这个属性-->
      <el-form :model="form" :rules="rules" ref="form">
        <el-form-item label="问题" label-width="60px" prop="question">
          <el-input v-model="form.question"></el-input>
        </el-form-item>
        <el-form-item label="类型" label-width="60px" prop="type">
          <el-select v-model="form.type" style="width:100%" placeholder="类型" @change="onChange">
            <el-option
              v-for="item in knowledgeTypes"
              :key="item.value"
              :label="item.label"
              :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="答案" label-width="60px" prop="answer" v-if="form.type=='text'">
          <el-input v-model="form.answer"></el-input>
        </el-form-item>
        <el-form-item prop="answer" v-if="form.type=='card'">
          <el-card style="margin-left: 60px;">
          <div>答案：</div>
            <el-card>
          <el-form-item label="标题" prop="answer.title" label-width="40px">
            <el-input v-model="form.answer.title"></el-input>
          </el-form-item>
            <div>列表：</div>
            <el-form-item style="width: 100%;">
              <div v-for="(item, index) in form.answer.list" :key="index" class="content">
                  <el-input v-model="form.answer.list[index]"
                  ></el-input>
                <el-button style="margin-left: 20px" type="danger"
                           icon="el-icon-delete"
                            @click="delListRow(index)"
                >删除</el-button
                >
              </div>
            </el-form-item>
              <el-button
                type="primary"
                icon="el-icon-plus"
                style="margin: 10px 0px"
                @click="addListRow"
              >添加
              </el-button>
              </el-card>
          </el-card>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="addOrUpdate"
        >确 定</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>

<script>
import BreadcrumbNav from '../common/BreadcrumbNav'
import ShopSelect from '../common/ShopSelect'
import {
  addKnowledgeRequest, delKnowledgeRequest,
  getKnowledgeListRequest, SortKnowledgeRequest,
  updateKnowledgeRequest
} from '../../network/customer'
export default {
  name: 'Knowledge',
  components: {
    BreadcrumbNav,
    ShopSelect
  },
  filters: {
    formatType (value) {
      switch (value) {
        case 'text':
          return '文本'
        case 'card':
          return '列表'
      }
    }
  },
  data () {
    return {
      shopId: -1,
      dialogFormVisible: false,
      form: {
        question: '',
        type: 'text',
        answer: '',
        shopId: -1
      },
      // 表单验证规则
      rules: {
        question: [
          { required: true, message: '请输入问题', trigger: 'blur' }
        ]
      },
      // 总共数据条数
      total: 0,
      // 列表展示的数据
      list: [],
      // 任务类型
      knowledgeTypes: [{
        label: '文本',
        value: 'text'
      }, {
        label: '列表',
        value: 'card'
      }]
    }
  },
  computed: {
    isBtnDisabled () {
      return this.shopId === -1
    }
  },
  methods: {
    shopChange (shopId) {
      console.log('-------this.shopId:', shopId)
      this.shopId = shopId
      this.form.shopId = shopId
      this.getList()
    },
    getList () {
      getKnowledgeListRequest({ shopId: this.shopId }).then(res => {
        const result = res.data
        if (res.status !== 200) {
          return this.alertMessage('获取客服列表失败', 'error')
        }
        this.list = result.data.list
        this.total = result.data.total
      })
    },
    updateKnowledge (row) {
      this.dialogFormVisible = true
      this.form = { ...row }
    },
    showDialog () {
      this.dialogFormVisible = true
      this.form = { question: '', type: 'text', answer: '', shopId: this.shopId }
    },
    update2Srv () {
      // eslint-disable-next-line no-prototype-builtins
      if (this.form.answer.hasOwnProperty('elm')) {
        delete (this.form.answer.elm)
      }
      // eslint-disable-next-line no-prototype-builtins
      if (this.form.answer.hasOwnProperty('isRootInsert')) {
        delete (this.form.answer.isRootInsert)
      }
      console.log('update form:', this.form)
      // eslint-disable-next-line no-unreachable
      updateKnowledgeRequest(this.form).then(res => {
        if (res.status !== 200) {
          return this.alertMessage('修改热门咨询失败', 'error')
        }
        this.alertMessage('修改热门咨询成功', 'success')
        this.getList()
      })
    },
    add2Srv () {
      addKnowledgeRequest(this.form).then(res => {
        if (res.status !== 200) {
          return this.alertMessage('添加热门咨询失败', 'error')
        }
        this.alertMessage('添加热门咨询成功', 'success')
        this.getList()
      })
    },
    addOrUpdate () {
      this.$refs.form.validate(valid => {
        if (!valid) return
        this.dialogFormVisible = false
        console.log('this.form:', this.form)
        if (this.form.id) {
          this.update2Srv()
        } else {
          this.add2Srv()
        }
      })
    },
    onChange () {
      if (this.form.type === 'text') {
        this.form.answer = ''
      } else {
        this.form.answer = {
          title: '',
          list: []
        }
      }
    },
    addListRow () {
      this.form.answer.list.push('')
    },
    delListRow (index) {
      console.log('del list row:', index)
      this.form.answer.list.splice(index, 1)
    },
    delKnowledge (row) {
      console.log('del row:', row)
      this.$confirm('此操作将永久删除, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        delKnowledgeRequest(row.id).then(res => {
          if (res.status !== 200) {
            return this.alertMessage('删除失败', 'error')
          }
          this.alertMessage('删除成功', 'success')
          this.getList()
        })
      }).catch(() => {
        this.alertMessage('已取消删除', 'info')
      })
    },
    upindex (index, row) {
      if (index > 0) {
        const curData = this.list[index]
        const upData = this.list[index - 1]
        const sortList = []
        sortList.push({ taskId: curData.id, sortNum: upData.sortNum })
        sortList.push({ taskId: upData.id, sortNum: curData.sortNum })
        SortKnowledgeRequest({ list: sortList }).then(res => {
          if (res.status !== 200) {
            return this.alertMessage('上移失败', 'error')
          }
          const curSortNum = curData.sortNum
          curData.sortNum = upData.sortNum
          upData.sortNum = curSortNum
          this.list.splice(index - 1, 1)
          this.list.splice(index, 0, upData)
        })
      } else {
        this.$message.warning('已经是第一条了！')
        return false
      }
    },
    downindex (index, row) {
      if (index + 1 === this.list.length) {
        this.$message.warning('已经是最后一条了！')
        return false
      } else {
        const downData = this.list[index + 1]
        const curData = this.list[index]
        const sortList = []
        sortList.push({ taskId: curData.id, sortNum: downData.sortNum })
        sortList.push({ taskid: downData.id, sortNum: curData.sortNum })
        SortKnowledgeRequest({ list: sortList }).then(res => {
          if (res.status !== 200) {
            return this.alertMessage('下移失败', 'error')
          }
          const curSortNum = curData.sortNum
          curData.sortNum = downData.sortNum
          downData.sortNum = curSortNum
          this.list.splice(index + 1, 1)
          this.list.splice(index, 0, downData)
        })
      }
    }
  }
}
</script>

<style>
.content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
  span{
    margin: 0 8px;
  }
  .change-icon {
    font-size: 26px;
    cursor: pointer;
  }
  .change-icon-add {
    font-size: 26px;
    cursor: no-drop;
  }
}
.numrule {
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }
  /* Firefox浏览器 */
  input[type="number"] {
    -moz-appearance: textfield;
  }
}
</style>
